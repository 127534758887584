
import { mapGetters, mapState } from "vuex";

export default {
  props: {
    product: { type: Object, default: () => {} },
    selectedVariant: { type: Object, default: () => {} },
    position: { type: Number, default: 0 },
    listname: { type: String, default: "" },
  },

  computed: {
    ...mapState("wishlist", ["modalTypes", "customer", "fillColor"]),
    ...mapGetters("wishlist", ["getItemByProductId"]),
    wishlistItem() {
      return this.getItemByProductId(this.product.id);
    },
    fill() {
      return this.wishlistItem ? this.fillColor : "#fff";
    },
    stroke() {
      return this.wishlistItem ? this.fillColor : "#333";
    },
  },

  methods: {
    handleHeartIconClick() {
      const payload = {
        listname: this.listname,
        position: this.position,
        product: this.product,
        selectedVariant: this.selectedVariant,
      };

      if (!this.customer?.email) {
        this.$store.dispatch("wishlist/openModal", {
          type: this.modalTypes.LOGIN_MODAL,
          modalOpenedFrom: "PRODDUCT_TILE",
          payload,
        });
        return;
      }

      this.$store.dispatch("wishlist/openModal", {
        type: this.modalTypes.WISHLIST_ADD_MODAL,
        payload,
      });
    },
  },
};
