import { render, staticRenderFns } from "./ProductTileIconHeart.vue?vue&type=template&id=343a6c26&"
import script from "./ProductTileIconHeart.vue?vue&type=script&lang=js&"
export * from "./ProductTileIconHeart.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconWishlist: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/dynamic/IconWishlist.vue').default})
