import { render, staticRenderFns } from "./IconWishlist.vue?vue&type=template&id=0b380222&scoped=true&"
import script from "./IconWishlist.vue?vue&type=script&lang=js&"
export * from "./IconWishlist.vue?vue&type=script&lang=js&"
import style0 from "./IconWishlist.vue?vue&type=style&index=0&id=0b380222&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b380222",
  null
  
)

export default component.exports