
import { mapState } from "vuex";

export default {
  props: {
    tags: { type: Array, default: () => [] },
  },

  computed: {
    ...mapState(["appSettings"]),
    badgeData() {
      const filteredBadge = this.appSettings?.badge_group?.filter(badge =>
        this.tags?.includes(badge.badgeTag)
      );
      return filteredBadge?.[0] ?? [];
    },
  },
};
