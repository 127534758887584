
export default {
  props: {
    type: {
      validator: value => ["prev", "next"].includes(value),
      default: true,
    },
    size: {
      validator: value => ["big", "small"].includes(value),
      default: true,
    },
  },
  computed: {
    style() {
      return this.type == "next"
        ? { transform: "rotate(-90deg)" }
        : { transform: "rotate(90deg)" };
    },
  },
};
